<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-width-1-1">
      <h1 class="uk-heading-line">
        {{ headerTitle }}
      </h1>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <edit
        :id="id"
        :list-regions="listRegions"
        :loading-detail="loadingDetail"
        :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
        :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
        :set-data-modal-add-edit="setDataModalAddEdit"
      />
    </div>
    <modal-add-edit-discard 
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :handle-modal-button="handleModalEdit"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { notificationSuccess } from '@/utils/notification'
import { PREFIX_IMAGE } from "@/utils/constant"
import Edit from './Edit'
import getHeaderTitle from '@/utils/header-title'
import ModalAddEditDiscard from '@/components/globals/modals/ModalAddEditDiscard'

export default {
  components: {
    Edit,
    ModalAddEditDiscard
  },
  data() {
    return {
      id: this.$route.params.id,
      images: PREFIX_IMAGE,
      loadingDetail: true
    }
  },
  computed: {
    ...mapGetters({
      listRegions: 'regions/list_regions',
      getDataModalAddEdit: 'exportHub/get_data_modal_add_edit',
      getToggleModalAddEditDiscard: 'exportHub/get_toggle_modal_add_edit_discard'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  async mounted() {
    this.loadingDetail
    await this.actionGetListRegions()
    await this.actionDetailExportHub(this.id)
    this.loadingDetail = false
  },
  methods: {
    ...mapActions({
      actionGetListRegions: 'regions/getListRegions',
      actionDetailExportHub: 'exportHub/getDetailExportHub',
      updateExportHub: 'exportHub/updateExportHub'
    }),
    ...mapMutations({
      setDataModalAddEdit: 'exportHub/SET_DATA_MODAL_ADD_EDIT',
      setToggleModalAddEditDiscard: 'exportHub/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    handleModalEdit () {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        type: 'save',
        loadingTable: false,
        loadingPopUp: true
      })
      const payload = {
        id: this.id,
        reqBody: {...this.getDataModalAddEdit}
      }
      this.updateExportHub(payload).then(result => {
        if (result.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard,
              loadingTable: false,
              loadingPopUp: false
            })
            notificationSuccess('Edit Export Hub Successful')
          }, 500)
          setTimeout(() => {
            this.$router.push({ name: 'ExportHub' })
            this.setToggleModalAddEditDiscard({
              targetModals: false,  
              title: '',
              description: '',
              type: '',
              customTitleBtnOk: '',
              customTitleBtnCancel: '',
              customClass: '',
              loadingTable: false,
              loadingPopUp: false
            })
          }, 1000)
        }
      })
    }
  }
}
</script>
